html {
  font-size: 1rem;
  margin: 0px;
  max-height: 100vh;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

#root {
  width: 100%;
}

a {
  text-decoration: none;
  font-size: 1rem;
}
textarea {
  resize: vertical; /* user can resize vertically, but width is fixed */
  font-family: Roboto;
}
.content {
  min-height: 100vh;
  font-family: "roboto";
}

.content p,
h3,
li {
  font-size: 1.2rem;
}
.content h1 {
  font-size: 2.2rem;
}

@keyframes wheel {
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(40deg);
  }
  50% {
    transform: rotate(-40deg);
  }
  70% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/*===colors===*/
:root {
  --bay: #32e0c4;
  --black: #393e46;
  --Dblack: #222831;
  --white: #ffffff;
}

/*===Buttons==*/
/*===Buttons - 1==*/
.pi-btn {
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  text-transform: capitalize;
  text-decoration: none !important;
  font-family: "Nunito";
  border-style: solid;
  border-color: var(--Dblack) !important;
  color: var(--Dblack);

  background-color: rgba(
    255,
    255,
    255,
    0.253
  ); /* Black w/opacity/see-through */
  backdrop-filter: blur(3px);
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  cursor: pointer;
}

.pi-btn > i {
  margin-left: 5px;
  vertical-align: center;
  padding: 0.5rem 0.5rem;
}

@keyframes move {
  0% {
    margin-left: 5px;
  }
  50% {
    margin-left: 8px;
  }
  100% {
    margin-left: 15px;
  }
}

.pi-btn:hover {
  border-color: var(--Dblack) !important;
  background-color: var(--black);
  color: var(--white);
}
.pi-btn:focus ~ i {
  animation: move 1s;
}

/*===Buttons - 2==*/
.pi-btn2 {
  position: relative;
  text-align: center;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  z-index: 2;
  text-transform: capitalize;
  text-decoration: none !important;
  font-family: "Nunito";
  border-style: solid;
  border-color: var(--black) !important;
  color: var(--Dblack);
  background-color: var(--white);
}
.pi-btn2 > i {
  padding-right: 1rem;
}

.pi-btn2:hover {
  border-color: var(--black) !important;
  background-color: var(--black);
  color: var(--white) !important;
  transition: 0.5s;
}

.section-heading {
  text-align: center;
  font-weight: 500;
  font-size: 3rem;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "poppins";
}


.note
{
  width: 100%;
  padding: .8rem 0;
  height: 1rem;
  display: grid;
  place-content: center;
  background-color: #ec3459;

}
.note p
{
  font-family:  Roboto;
  color : var(--white);
  font-size: .8rem;
}
.note p > a
{
  font-size: .8rem;
  font-family:  Roboto;
  color : var(--white);
  text-decoration: underline;
}


@media all and (max-width: 599px) {
  /* for-phone */

  .section-heading {
    text-align: center;
    font-weight: 500;
    font-size: 2rem !important;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: "poppins";
  }

  /*===========================
nav 2
==========================*/
  .nav2 {
    text-align: center;
    display: grid;
    height: 50px;
    padding-top: 0px;
    grid-template-columns: 10% 90%;
    -webkit-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
  }
  .nav2 a i {
    font-size: 2rem;
    line-height: 80px;
    margin-left: 25px;
    color: var(--Dblack);
  }
  .nav2 a i:hover {
    font-size: 2rem;
    line-height: 80px;
    color: var(--bay);
  }

  .nav2 h2 {
    font-size: 1.5rem;
    margin: 0px;
    color: var(--Dblack);
    font-family: "Nunito";
    line-height: 80px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* ==== Navbar  ==== */
  nav {
    height: 15vh;
    width: 100%;
    padding: 25px 0px;
  }

  .logo img {
    margin-left: 25px;
    text-align: center;
    width: 4rem;
    height: 4rem;
    float: left;
    display: block;
    font-size: 2rem;
    text-align: left;
  }

  #close-menu {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 2.5em;
    color: var(--black);
  }

  #check-box {
    visibility: hidden;
  }

  #menu-img {
    background-repeat: no-repeat;
    font-size: 3rem;
    float: right;
    margin-right: 30px;
  }

  #holder {
    transition: ease-out;
    z-index: 12;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    margin-top: 0px;
    list-style: none;
    background-color: transparent;
  }

  #holder li {
    margin-top: 50px;
  }

  #holder li a {
    text-transform: capitalize;
    float: none;
    font-size: 1.2rem;
    font-family: "poppins";
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  #holder li a:hover {
    padding: 2%;
    border: 2px solid var(--bay);
    transition: ease-in 0.3s;
  }

  #check-box:checked ~ #holder {
    right: 0;
    background: linear-gradient(
        123.09deg,
        #79fce7 -0.92%,
        rgba(255, 255, 255, 0) 79.26%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  /* ==== Navbar ends  ==== */

  /* ==== Home ==== */
  #home {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(
        178.23deg,
        rgba(121, 252, 231, 0.7) 17.54%,
        rgba(255, 255, 255, 0) 98.5%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  .title {
    display: block;
    text-align: center;
    padding: 1%;
    font-size: 1.5em;
    font-family: "poppins";
    position: relative;
    z-index: 10;
  }

  .pi-btn {
    display: inline-block;
    z-index: 10;
    left: 50;
    position: relative;
  }

  .boat {
    height: 8rem;
    width: 8rem;
    position: absolute;
    z-index: 2;
    bottom: 90px;
    left: 25px;
  }

  .sea {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
  }

  .get {
    text-align: center;
    margin-top: 5rem;
  }

  .sun {
    position: absolute;
    right: 20vh;
    left: 30vw;
    bottom: 65px;
    height: 22rem;
    width: 22rem;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 164, 79, 0.42) 60.47%,
      rgba(205, 114, 21, 0) 118.59%
    );
    opacity: 0.6;
  }

  .land {
    display: none;
  }

  /*Service*/

  .service-row {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: minmax(1, 1fr);
  }
  .pic-side {
    text-align: center;
  }

  .service-heading {
    text-align: left !important;
    font-family: "Nunito";
    font-size: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    text-transform: capitalize;
    font-weight: 600;
  }

  .service-img {
    margin-top: 3rem;
    width: 250px;
    height: 250px;
  }

  .info-side {
    font-size: 1rem;
    line-height: 2rem;
    padding: 2%;

    font-family: "Roboto";
  }
  .btn-holder {
    padding-top: 2rem;
    text-align: center;
  }

  /*Testimonials*/
  .testimonials-rows {
    display: grid;
    grid-template-columns: minmax(1, 1fr 1fr);
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .testimonial-pic {
    min-height: 200px;
    text-align: center;
  }
  .person-bg {
    display: inline-block;
    text-align: center;
    height: 280px;
    width: 280px;
    background-image: url(../img/person-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .person {
    position: relative;
    top: 40px;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
    display: initial !important;
  }
  .testimonial-review {
    min-height: 200px;
    padding: 1rem;
    background-color: var(--white);
    border-radius: 25px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.07);
    margin-bottom: 4rem;
  }

  .review {
    line-height: 1.8rem;
    font-family: "Roboto";
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
  }

  .client-details {
    margin-top: 3rem;
    height: 50px;
    text-align: right;
  }

  .client-name {
    margin-top: 2rem;
    color: var(--black);
    text-transform: capitalize;
    font-weight: 900;
    font-size: 1.2rem;
    font-family: "Nunito";
    color: var(--Dblack);
    margin-bottom: 0px;
  }
  .website {
    color: var(--black);
    text-transform: lowercase;
    font-style: italic;
    font-family: "Roboto";
    color: var(--black);
    font-size: 1rem;
  }

  /*About*/

  #about {
    margin-top: 10vh;
    min-height: 60vh;
  }

  .about-row {
    display: grid;
    grid-template-columns: minmax(1, 1fr);
    gap: 1rem;
  }

  .about-heading {
    margin-top: 2rem;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2rem;
  }

  .about-box {
    margin-top: 3rem;
    height: 100%;
    background-color: var(--white);
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .logo-box {
    width: 250px;
    height: 250px;
    margin: 0px auto;
  }

  .Social-media-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 4rem;
    height: 100px;
  }

  .soc-round {
    margin: 0px auto;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--bay);
    text-align: center;
    color: var(--Dblack);
    transition: ease-in 300ms;
  }

  .soc-round > i {
    font-size: 1.2rem;
    line-height: 50px;
  }

  .soc-round:hover {
    margin: 0px auto;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--Dblack);
    color: var(--white);
    text-align: center;
  }
  .soc-round > i:hover {
    font-size: 1.2rem;
    line-height: 50px;
  }

  .about-info {
    padding: 0px 8px;
  }

  #about-text {
    line-height: 1.8rem;
    font-size: 1rem;
    font-family: "Roboto";
  }

  /*contact*/
  #contact {
    min-height: 90vh;
  }
  .con-bg {
    background-image: url(../img/contact_bg.png);
    display: block;
    width: 100% !important;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }

  .contact-row {
    display: grid;
    grid-template-columns: minmax(1, 1fr);
    gap: 3rem;
    margin-bottom: 3rem;
  }

  .con-left {
    padding: 0px 40px;
  }
  .contact-item {
    min-height: 90px;
    width: 100%;
  }

  #contact-icon {
    font-size: 2rem;
    color: var(--Dblack);
  }

  #info-head {
    font-size: 1.5rem;
    font-family: "Nunito";
    display: inline-block;
    padding-left: 1rem;
    margin-bottom: 0.8rem;
  }

  #contact-info {
    display: block;
    font-family: "Roboto";
    margin-top: 0px;
    font-size: 1rem;
    margin-left: 2.9rem;
    text-decoration: none;
    color: var(--black);
  }

  .con-right {
    padding: 0px 10px;
  }

  .contact-box {
    padding: 8%;
    text-align: left;
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
  }
  .box-heading {
    font-size: 1.5rem;
    margin-top: 0px;
    font-family: "Nunito";
    color: var(--Dblack);
    font-weight: 800;
  }

  .text-box {
    padding: 3%;
    width: 90%;
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-size: 1.2rem;
    box-shadow: -4px -2px 8px rgba(0, 0, 0, 0.161);
  }

  .con-btn {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
    margin-top: 30px;
    background-color: var(--white);
    border-radius: 25px;
    padding: 1rem 1.2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .con-btn:hover {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.2);
  }

  .con-btn > i {
    color: var(--Dblack);
    padding-right: 15px;
    font-size: 1em;
    cursor: pointer;
  }

  /*==footer==*/

  #footer {
    min-height: 30vh;
  }

  .footer-bg {
    background-color: var(--black);
  }

  .footer-row {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .footer-logo img {
    width: 100px;
    height: 100px;
    margin-top: 1.5rem;
  }

  .footer-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: "Nunito";
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .logo-text {
    font-size: 1rem;
    color: var(--white);
    font-family: "Roboto";
    margin-left: 1.4rem;
  }

  .footer-list {
    list-style: none;
    padding-left: 1rem;
  }
  .footer-list > li {
    margin-bottom: 1rem;
  }
  .footer-list > li > a {
    font-size: 1rem;
    color: var(--bay);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a:hover {
    font-size: 1rem;
    color: var(--white);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a > i {
    margin-right: 1rem;
  }

  .copyrigths {
    background-color: var(--Dblack);
    text-align: center;
  }
  #copyrigths-text {
    font-size: 1rem;
    margin: 0px;
    line-height: 2.5rem;
    text-transform: capitalize;
    font-family: "Roboto";
    color: var(--white);
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  /* for-tablet */

  /*===========================
nav 2
==========================*/
  .nav2 {
    text-align: center;
    display: grid;
    height: 50px;
    padding-top: 0px;
    grid-template-columns: 10% 90%;
    -webkit-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
  }
  .nav2 a i {
    font-size: 2rem;
    line-height: 80px;
    margin-left: 25px;
    color: var(--Dblack);
  }
  .nav2 a i:hover {
    font-size: 2rem;
    line-height: 80px;
    color: var(--bay);
  }

  .nav2 h2 {
    font-size: 1.5rem;
    margin: 0px;
    color: var(--Dblack);
    font-family: "Nunito";
    line-height: 80px;
  }

  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  /* ==== Navbar  ==== */
  nav {
    height: 15vh;
    width: 100%;
    padding: 25px 0px;
  }

  .logo img {
    margin-left: 25px;
    text-align: center;
    width: 4rem;
    height: 4rem;
    float: left;
    display: block;
    font-size: 2rem;
    text-align: left;
  }

  #close-menu {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 2.5em;
    color: var(--black);
  }

  #check-box {
    visibility: hidden;
  }

  #menu-img {
    background-repeat: no-repeat;
    font-size: 3rem;
    float: right;
    margin-right: 30px;
  }

  #holder {
    transition: ease-out;
    z-index: 12;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    margin-top: 0px;
    list-style: none;
    background-color: transparent;
  }

  #holder li {
    margin-top: 50px;
  }

  #holder li a {
    text-transform: capitalize;
    float: none;
    font-size: 1.2rem;
    font-family: "poppins";
    text-decoration: none;
    color: black;
  }

  #holder li a:hover {
    padding: 2%;
    border: 2px solid var(--bay);
    transition: ease-in 0.3s;
  }

  #check-box:checked ~ #holder {
    right: 0;
    background: linear-gradient(
        123.09deg,
        #79fce7 -0.92%,
        rgba(255, 255, 255, 0) 79.26%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  /* ==== Navbar ends  ==== */

  /* ==== Home ==== */
  #home {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(
        178.23deg,
        rgba(121, 252, 231, 0.7) 17.54%,
        rgba(255, 255, 255, 0) 98.5%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  .title {
    display: block;
    text-align: center;
    padding: 1%;
    font-size: 1.5em;
    font-family: "poppins";
    position: relative;
    z-index: 10;
  }

  .pi-btn {
    display: inline-block;
    z-index: 10;
    left: 50;
    position: relative;
  }

  .boat {
    height: 8rem;
    width: 8rem;
    position: absolute;
    z-index: 2;
    bottom: 85px;
    left: 25px;
  }

  .sea {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
  }

  .get {
    text-align: center;
    margin-top: 5rem;
  }

  .sun {
    position: absolute;
    right: 20vh;
    left: 60vw;
    bottom: 90px;
    height: 18rem;
    width: 18rem;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 164, 79, 0.42) 60.47%,
      rgba(205, 114, 21, 0) 118.59%
    );
    opacity: 0.6;
  }

  .land {
    display: none;
  }

  /*Service*/

  .service-row {
    margin-bottom: 2.5rem;
    display: grid;
    grid-template-columns: minmax(1, 1fr);
  }
  .pic-side {
    text-align: center;
  }

  .service-heading {
    text-align: left !important;
    font-family: "Nunito";
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  .service-img {
    margin-top: 2.5rem;
    width: 250px;
    height: 250px;
  }

  .info-side {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2%;
    font-family: "Roboto";
    margin-bottom: 1rem;
  }
  .btn-holder {
    margin-top: 3rem;
    text-align: center;
  }

  /*Testimonials*/
  .testimonials-rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .testimonial-pic {
    min-height: 200px;
    text-align: center;
  }
  .person-bg {
    display: inline-block;
    text-align: center;
    height: 280px;
    width: 280px;
    background-image: url(../img/person-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .person {
    position: relative;
    top: 40px;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
    display: initial !important;
  }
  .testimonial-review {
    min-height: 200px;
    padding: 1.5rem;
    background-color: var(--white);
    border-radius: 25px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.07);
    margin-bottom: 5rem;
  }

  .review {
    font-family: "Roboto";
    text-align: center;
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .client-details {
    margin-top: 3rem;
    height: 50px;
    text-align: right;
  }

  .client-name {
    margin-top: 2rem;
    color: var(--black);
    text-transform: capitalize;
    font-weight: 900;
    font-size: 1.2rem;
    font-family: "Nunito";
    color: var(--Dblack);
    margin-bottom: 0px;
  }
  .website {
    color: var(--black);
    text-transform: lowercase;
    font-style: italic;
    font-family: "Roboto";
    color: var(--black);
    font-size: 1rem;
  }

  /*About*/

  #about {
    margin-top: 10vh;
    min-height: 60vh;
  }

  .about-row {
    display: grid;
    grid-template-columns: minmax(1, 1fr);
    gap: 3rem;
  }

  .about-heading {
    margin-top: 3rem;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 3rem;
  }

  .about-box {
    margin-top: 3rem;
    height: 100%;
    background-color: var(--white);
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .logo-box {
    width: 200px;
    height: 200px;
    margin: 0px auto;
  }

  .Social-media-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 4rem;
    padding: 0px 100px;
    height: 100px;
  }

  .soc-round {
    margin: 0px auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--bay);
    text-align: center;
    color: var(--Dblack);
    transition: ease-in 300ms;
  }

  .soc-round > i {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .soc-round:hover {
    margin: 0px auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--Dblack);
    color: var(--white);
    text-align: center;
  }
  .soc-round > i:hover {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .about-info {
    padding: 0px 15px;
  }

  #about-text {
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-family: "Roboto";
  }

  /*contact*/

  #contact {
    min-height: 80vh;
  }
  .con-bg {
    background-image: url(../img/contact_bg.png);
    display: block;
    width: 100vw !important;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    clear: both;
  }

  .contact-row {
    display: grid;
    grid-template-columns: minmax(1, 1fr);
    gap: 4rem;
    margin-bottom: 3rem;
  }

  .con-left {
    padding: 0px 60px;
  }
  .contact-item {
    min-height: 90px;
    width: 100%;
  }

  #contact-icon {
    font-size: 2rem;
    color: var(--Dblack);
  }

  #info-head {
    font-size: 1.5rem;
    font-family: "Nunito";
    display: inline-block;
    padding-left: 1rem;
    margin-bottom: 0.8rem;
  }

  #contact-info {
    display: block;
    font-family: "Roboto";
    margin-top: 0px;
    font-size: 1rem;
    margin-left: 2.6rem;
    text-decoration: none;
    color: var(--black);
  }

  .con-right {
    padding: 0px 60px;
  }

  .contact-box {
    padding: 8%;
    text-align: left;
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
  }
  .box-heading {
    font-size: 2.5rem;
    margin-top: 0px;
    font-family: "Nunito";
    color: var(--Dblack);
    font-weight: 800;
  }

  .text-box {
    padding: 3%;
    width: 90%;
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-size: 1.2rem;
    box-shadow: -4px -2px 8px rgba(0, 0, 0, 0.161);
  }

  .con-btn {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    background-color: var(--white);
    border-radius: 25px;
    padding: 1rem 1.5rem;
    border: none;
    font-size: 1em;
    cursor: pointer;
  }

  .con-btn:hover {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.2);
  }

  .con-btn > i {
    color: var(--Dblack);
    padding-right: 15px;
    font-size: 1em;
    cursor: pointer;
  }

  /*==footer==*/

  #footer {
    height: 30vh;
  }

  .footer-bg {
    background-color: var(--black);
  }

  .footer-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .footer-logo img {
    width: 100px;
    height: 100px;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
  }

  .footer-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: "Nunito";
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .logo-text {
    font-size: 1rem;
    color: var(--white);
    font-family: "Roboto";
    margin-left: 2.8rem;
  }

  .footer-list {
    list-style: none;
    padding-left: 0rem;
  }
  .footer-list > li {
    margin-bottom: 1rem;
  }
  .footer-list > li > a {
    font-size: 1rem;
    color: var(--bay);
    text-transform: capitalize;
    font-family: "Roboto";
  }
  .footer-list > li > a:hover {
    font-size: 1rem;
    color: var(--white);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a > i {
    margin-right: 1rem;
  }

  .copyrigths {
    background-color: var(--Dblack);
    text-align: center;
  }
  #copyrigths-text {
    font-size: 1rem;
    margin: 0px;
    line-height: 2.5rem;
    text-transform: capitalize;
    font-family: "Roboto";
    color: var(--white);
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  /* for-tablet-lg */

  /*===========================
nav 2
==========================*/
  .nav2 {
    text-align: center;
    display: grid;
    height: 50px;
    padding-top: 0px;
    grid-template-columns: 10% 90%;
    -webkit-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
  }
  .nav2 a i {
    font-size: 2rem;
    line-height: 80px;
    margin-left: 25px;
    color: var(--Dblack);
  }
  .nav2 a i:hover {
    font-size: 2rem;
    line-height: 80px;
    color: var(--bay);
  }

  .nav2 h2 {
    font-size: 1.5rem;
    margin: 0px;
    color: var(--Dblack);
    font-family: "Nunito";
    line-height: 80px;
  }

  .container {
    padding-left: 50px;
    padding-right: 50px;
  }

  /* ==== Navbar  ==== */
  nav {
    height: 15vh;
    width: 100%;
    padding: 25px 0px;
  }

  .logo img {
    margin-left: 25px;
    text-align: center;
    width: 5rem;
    height: 5rem;
    float: left;
    display: block;
    font-size: 2rem;
    text-align: left;
  }

  #close-menu {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 3em;
    color: var(--black);
  }

  #check-box {
    visibility: hidden;
  }

  #menu-img {
    background-repeat: no-repeat;
    font-size: 3rem;
    float: right;
    margin-right: 30px;
  }

  #holder {
    transition: ease-out;
    z-index: 12;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    margin-top: 0px;
    list-style: none;
    background-color: transparent;
  }

  #holder li {
    margin-top: 50px;
  }

  #holder li a {
    text-transform: capitalize;
    float: none;
    font-size: 1.2rem;
    font-family: "poppins";
    text-decoration: none;
    color: black;
  }

  #holder li a:hover {
    padding: 2%;
    border: 2px solid var(--bay);
    transition: ease-in 0.3s;
  }

  #check-box:checked ~ #holder {
    right: 0;
    background: linear-gradient(
        123.09deg,
        #79fce7 -0.92%,
        rgba(255, 255, 255, 0) 79.26%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  /* ==== Navbar ends  ==== */

  /* ==== Home ==== */
  #home {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: center;
    background: linear-gradient(
        178.23deg,
        rgba(121, 252, 231, 0.7) 17.54%,
        rgba(255, 255, 255, 0) 98.5%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  .title {
    display: block;
    text-align: center;
    padding: 1%;
    font-size: 2rem;
    font-family: "poppins";
    position: relative;
    z-index: 10;
  }

  .pi-btn {
    display: inline-block;
    z-index: 10;
    left: 50;
    position: relative;
  }

  .boat {
    height: 8rem;
    width: 8rem;
    position: absolute;
    z-index: 2;
    bottom: 85px;
    left: 25px;
  }

  .sea {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
  }

  .get {
    text-align: center;
    margin-top: 5rem;
  }

  .sun {
    position: absolute;
    right: 20vh;
    left: 55vw;
    bottom: 90px;
    height: 18rem;
    width: 18rem;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 164, 79, 0.42) 60.47%,
      rgba(205, 114, 21, 0) 118.59%
    );
    opacity: 0.6;
  }

  .land {
    position: absolute;
    right: 0;
    bottom: 95px;
    height: 150px;
    width: 400px;
  }

  /*Service*/

  .service-row {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .pic-side {
    text-align: center;
  }

  .service-heading {
    text-align: left !important;
    font-family: "Nunito";
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  .service-img {
    display: block;
    margin: auto;
    margin-top: 20%;
    width: 250px;
    height: 250px;
  }

  .info-side {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2%;
    font-family: "Roboto";
    margin-bottom: 1rem;
  }
  .btn-holder {
    padding-top: 2rem;
    text-align: left;
  }

  /*Testimonials*/

  #testimonials {
    min-height: 60vh;
  }
  .testimonial-row {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .testimonial-pic {
    min-height: 200px;
    text-align: center;
    background-color: var(--white);
    border-radius: 25px 0px 0px 25px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }
  .person-bg {
    margin-top: 2.5rem;
    display: inline-block;
    text-align: center;
    height: 280px;
    width: 280px;
    background-image: url(../img/person-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .person {
    position: relative;
    top: 50px;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
    display: initial !important;
  }
  .testimonial-review {
    min-height: 200px;
    padding: 1.5rem;
    background-color: var(--white);
    border-radius: 0px 25px 25px 0px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }

  .review {
    margin-top: 3rem;
    font-family: "Roboto";
    text-align: center;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .client-details {
    margin-top: 3rem;
    height: 50px;
    text-align: right;
  }

  .client-name {
    margin-top: 2rem;
    color: var(--black);
    text-transform: capitalize;
    font-weight: 900;
    font-size: 1.2rem;
    font-family: "Nunito";
    color: var(--Dblack);
    margin-bottom: 0px;
  }
  .website {
    color: var(--black);
    text-transform: lowercase;
    font-style: italic;
    font-family: "Roboto";

    color: var(--black);
    font-size: 1rem;
  }

  /*About*/

  #about {
    margin-top: 10vh;
    min-height: 70vh;
  }

  .about-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .about-heading {
    margin-top: 3rem;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 3rem;
  }

  .about-box {
    height: 100%;
    background-color: var(--white);
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .logo-box {
    width: 250px;
    height: 250px;
    margin: auto auto;
  }

  .Social-media-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 3rem;
    padding: 0px 15px;
    height: 100px;
  }

  .soc-round {
    margin: 0px auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--bay);
    text-align: center;
    color: var(--Dblack);
    transition: ease-in 300ms;
  }

  .soc-round > i {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .soc-round:hover {
    margin: 0px auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--Dblack);
    color: var(--white);
    text-align: center;
  }
  .soc-round > i:hover {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .about-info {
    padding: 0px 15px;
  }

  #about-text {
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-family: "Roboto";
  }

  /*contact*/

  #contact {
    min-height: 90vh;
  }
  .con-bg {
    background-image: url(../img/contact_bg.png);
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  .contact-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 3rem;
  }

  .con-left {
    padding: 0px 60px;
  }
  .contact-item {
    min-height: 90px;
    width: 100%;
    margin-top: 1rem;
  }

  #contact-icon {
    font-size: 2rem;
    color: var(--Dblack);
  }

  #info-head {
    font-size: 1.5rem;
    font-family: "Nunito";
    display: inline-block;
    padding-left: 1rem;
    margin-bottom: 0.8rem;
  }

  #contact-info {
    display: block;
    font-family: "Roboto";
    margin-top: 0px;
    font-size: 1rem;
    margin-left: 2.6rem;
    text-decoration: none;
    color: var(--black);
  }

  .con-right {
    padding: 0px 0px;
  }

  .contact-box {
    padding: 8%;
    text-align: left;
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
  }
  .box-heading {
    font-size: 2.5rem;
    margin-top: 0px;
    font-family: "Nunito";
    color: var(--Dblack);
    font-weight: 800;
  }

  .text-box {
    padding: 3%;
    width: 90%;
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-size: 1.2rem;
    box-shadow: -4px -2px 8px rgba(0, 0, 0, 0.161);
  }

  .con-btn {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
    margin-top: 30px;
    background-color: var(--white);
    border-radius: 25px;
    padding: 1rem 1.2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .con-btn:hover {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.25);
  }

  .con-btn > i {
    color: var(--Dblack);
    padding-right: 15px;
    font-size: 1em;
    cursor: pointer;
  }

  /*==footer==*/

  #footer {
    height: 30vh;
  }

  .footer-bg {
    background-color: var(--black);
  }

  .footer-row {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 4fr));
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .footer-logo img {
    width: 100px;
    height: 100px;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
  }

  .footer-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: "Nunito";
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .logo-text {
    font-size: 1rem;
    color: var(--white);
    font-family: "Roboto";
    margin-left: 2.8rem;
  }

  .footer-list {
    list-style: none;
    padding-left: 0rem;
  }
  .footer-list > li {
    margin-bottom: 1rem;
  }
  .footer-list > li > a {
    font-size: 1rem;
    color: var(--bay);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a:hover {
    font-size: 1rem;
    color: var(--white);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a > i {
    margin-right: 1rem;
  }
  .copyrigths {
    background-color: var(--Dblack);
    text-align: center;
  }
  #copyrigths-text {
    font-size: 1rem;
    margin: 0px;
    line-height: 2.5rem;
    text-transform: capitalize;
    font-family: "Roboto";
    color: var(--white);
  }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  /* for-desktop*/

  /*===========================
nav 2
==========================*/
  .nav2 {
    text-align: center;
    display: grid;
    height: 60px;
    padding-top: 0px;
    grid-template-columns: 10% 90%;
    -webkit-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
  }
  .nav2 a i {
    font-size: 2rem;
    line-height: 80px;
    margin-left: 25px;
    color: var(--Dblack);
  }
  .nav2 a i:hover {
    font-size: 2rem;
    line-height: 80px;
    color: var(--bay);
  }

  .nav2 h2 {
    font-size: 1.5rem;
    margin: 0px;
    color: var(--Dblack);
    font-family: "Nunito";
    line-height: 80px;
  }

  .container {
    padding-left: 15%;
    padding-right: 15%;
  }
  /* ==== Navbar  ==== */
  nav {
    height: 5rem;
    width: 100%;
    padding: 25px 0px;
  }

  .logo img {
    margin-left: 70px;
    text-align: center;
    width: 5rem;
    height: 5rem;
    float: left;
    display: block;
    font-size: 2rem;
    text-align: left;
  }

  #close-menu,
  #menu-bar,
  #menu-img {
    display: none;
  }

  #check-box {
    visibility: hidden;
  }

  #holder {
    display: block;
    position: relative;
    float: right;
    text-align: center;
    margin-top: 30px;
    transition: 0.6s;
    width: 35em;
    height: 3em;
    list-style: none;
  }

  #holder li {
    margin-top: 0px;
  }

  #holder li a {
    text-transform: capitalize;
    float: left;
    visibility: visible;
    font-family: "Nunito";
    font-size: 1.2em;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    margin-right: 3em;
    color: black;
  }

  #holder li a::after {
    content: "";
    margin-top: 3px;
    height: 3px;
    width: 100%;
    background-color: var(--Dblack);
  }

  #holder li a:hover {
    color: var(--black);
  }

  #holder li a:hover::after {
    display: block;
    background-color: var(--black);
    transition: 0.7s;
  }

  /* #check-box:checked ~ #holder {
    background-color: var(--White);
    display: block;
    width: 50em;
  } */

  /* #check-box:checked ~ #holder > li > a {
    visibility: visible;
  } */
  /* ==== Navbar ends  ==== */

  /* ==== Home ==== */
  #home {
    min-height: 115vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(
        178.23deg,
        rgba(121, 252, 231, 0.7) 17.54%,
        rgba(255, 255, 255, 0) 98.5%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  .title {
    display: block;
    text-align: left;
    padding: 0.5%;
    font-size: 2.5em;
    font-family: "poppins";
    margin: 50px 0px 30px 150px;
    z-index: 9;
  }

  .pi-btn {
    margin-left: 155px;
  }

  .boat {
    height: 10rem;
    width: 10rem;
    position: absolute;
    z-index: 1;
    left: 25px;
    bottom: 180px;
    right: 50vw;
    margin-left: 15vw;
  }

  .sea {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 200px;
  }

  .sun {
    position: absolute;
    right: 20vh;
    left: 43vw;
    bottom: 90px;
    height: 30rem;
    width: 30rem;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 164, 79, 0.42) 60.47%,
      rgba(205, 114, 21, 0) 118.59%
    );
    opacity: 0.6;
  }

  .land {
    position: absolute;
    right: 0;
    bottom: 200px;
    height: 200px;
    width: 500px;
  }

  /* ==== Service ==== */

  /*Service*/
  #service {
    min-height: 100vh;
  }

  .service-row {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 50vh;
    margin-bottom: 6rem;
  }
  .pic-side {
    text-align: center;
  }

  .service-heading {
    text-align: left !important;
    font-family: "Nunito";
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  .service-img {
    display: block;
    margin: auto;
    margin-top: 20%;
    width: 250px;
    height: 250px;
  }

  .info-side {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2%;
    font-family: "Roboto";
    margin-bottom: 1rem;
  }

  .btn-holder {
    padding-top: 2rem;
    text-align: left;
  }

  /*Testimonials*/

  #testimonials {
    min-height: 60vh;
  }
  .testimonial-row {
    margin-top: 5rem;
    z-index: 1;
    left: 25px;
    bottom: 3em;
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .testimonial-pic {
    min-height: 200px;
    text-align: center;
    background-color: var(--white);
    border-radius: 25px 0px 0px 25px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }
  .person-bg {
    display: inline-block;
    text-align: center;
    height: 280px;
    width: 280px;
    background-image: url(../img/person-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .person {
    position: relative;
    top: 50px;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
    display: initial !important;
  }
  .testimonial-review {
    min-height: 200px;
    padding: 1.5rem;
    background-color: var(--white);
    border-radius: 0px 25px 25px 0px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }

  .review {
    margin-top: 3rem;
    font-family: "Roboto";
    text-align: center;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .client-details {
    margin-top: 3rem;
    height: 50px;
    text-align: right;
  }

  .client-name {
    margin-top: 2rem;
    color: var(--black);
    text-transform: capitalize;
    font-weight: 900;
    font-size: 1.2rem;
    font-family: "Nunito";
    color: var(--Dblack);
    margin-bottom: 0px;
  }
  .website {
    color: var(--black);
    text-transform: lowercase;
    font-style: italic;
    font-family: "Roboto";

    color: var(--black);
    font-size: 1rem;
  }

  /*About*/

  #about {
    margin-top: 10vh;
    min-height: 75vh;
  }

  .about-row {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 2rem;
  }

  .about-heading {
    margin-top: 3rem;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 3rem;
  }

  .about-box {
    height: 100%;
    background-color: var(--white);
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .logo-box {
    width: 350px;
    height: 350px;
    margin: auto auto;
  }

  #about-text {
    word-wrap: break-word;
  }

  .Social-media-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 4rem;
    padding: 0px 50px;
    height: 100px;
  }

  .soc-round {
    margin: auto auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--bay);
    text-align: center;
    color: var(--Dblack);
    transition: ease-in 300ms;
  }

  .soc-round > i {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .soc-round:hover {
    margin: auto auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--Dblack);
    color: var(--white);
    text-align: center;
  }
  .soc-round > i:hover {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .about-info {
    padding: 0px 15px;
  }

  #about-text {
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-family: "Roboto";
  }

  /*contact*/

  #contact {
    min-height: 90vh;
  }

  .con-bg {
    background-image: url(../img/contact_bg.png);
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  .contact-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 4rem;
    gap: 0.2rem;
  }

  .con-left {
    padding: 0px 60px;
  }
  .contact-item {
    min-height: 90px;
    width: 100%;
    margin-top: 1rem;
  }

  #contact-icon {
    font-size: 2rem;
    color: var(--Dblack);
  }

  #info-head {
    font-size: 1.5rem;
    font-family: "Nunito";
    display: inline-block;
    padding-left: 1rem;
    margin-bottom: 0.8rem;
  }

  #contact-info {
    display: block;
    font-family: "Roboto";
    margin-top: 0px;
    font-size: 1rem;
    margin-left: 2.6rem;
    text-decoration: none;
    color: var(--black);
  }

  .con-right {
    padding: 0px 0px;
  }

  .contact-box {
    padding: 8%;
    text-align: left;
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
  }
  .box-heading {
    font-size: 2.5rem;
    margin-top: 0px;
    font-family: "Nunito";
    color: var(--Dblack);
    font-weight: 800;
  }

  .text-box {
    padding: 3%;
    width: 90%;
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-size: 1.2rem;
    box-shadow: -4px -2px 8px rgba(0, 0, 0, 0.161);
  }

  .con-btn {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
    margin-top: 30px;
    background-color: var(--white);
    border-radius: 25px;
    padding: 1rem 1.2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .con-btn:hover {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.1);
  }

  .con-btn > i {
    color: var(--Dblack);
    padding-right: 15px;
    font-size: 1em;
    cursor: pointer;
  }

  /*==footer==*/

  #footer {
    margin-top: 2.5rem;
    max-height: 30vh;
  }

  .footer-bg {
    background-color: var(--black);
  }

  .footer-row {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .footer-logo img {
    width: 100px;
    height: 100px;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
  }

  .footer-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: "Nunito";
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .logo-text {
    font-size: 1rem;
    color: var(--white);
    font-family: "Roboto";
    margin-left: 2.6rem;
  }

  .footer-list {
    list-style: none;
    padding-left: 0rem;
  }
  .footer-list > li {
    margin-bottom: 1rem;
  }
  .footer-list > li > a {
    font-size: 1rem;
    color: var(--bay);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a:hover {
    font-size: 1rem;
    color: var(--white);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a > i {
    margin-right: 1rem;
  }
  .copyrigths {
    background-color: var(--Dblack);
    text-align: center;
  }
  #copyrigths-text {
    font-size: 1rem;
    margin: 0px;
    line-height: 2.5rem;
    text-transform: capitalize;
    font-family: "Roboto";
    color: var(--white);
  }
}

@media (min-width: 1800px) {
  /* for-big-desktop */

  /*===========================
nav 2
==========================*/
  .nav2 {
    text-align: center;
    display: grid;
    height: 60px;
    padding-top: 0px;
    grid-template-columns: 10% 90%;
    -webkit-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 7px 6px -2px rgba(0, 0, 0, 0.75);
  }
  .nav2 a i {
    font-size: 2rem;
    line-height: 80px;
    margin-left: 25px;
    color: var(--Dblack);
  }
  .nav2 a i:hover {
    font-size: 2rem;
    line-height: 80px;
    color: var(--bay);
  }

  .nav2 h2 {
    font-size: 1.5rem;
    margin: 0px;
    color: var(--Dblack);
    font-family: "Nunito";
    line-height: 80px;
  }

  .container {
    padding-left: 18%;
    padding-right: 18%;
  }

  /* ==== Navbar  ==== */
  nav {
    height: 5rem;
    width: 100%;
    padding: 25px 0px;
  }

  .logo img {
    margin-left: 70px;
    text-align: center;
    width: 7rem;
    height: 7rem;
    float: left;
    display: block;
    font-size: 2rem;
    text-align: left;
  }

  #close-menu,
  #menu-bar,
  #menu-img {
    display: none;
  }

  #check-box {
    visibility: hidden;
  }

  #holder {
    display: block;
    position: relative;
    float: right;
    text-align: center;
    margin-top: 30px;
    transition: 0.6s;
    width: 50em;
    height: 3em;
    list-style: none;
  }

  #holder li {
    margin-top: 0px;
  }

  #holder li a {
    text-transform: capitalize;
    float: left;
    visibility: visible;
    font-family: "Nunito";
    font-size: 1.2em;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    margin-right: 3.2em;
    color: black;
  }

  #holder li a::after {
    content: "";
    margin-top: 3px;
    height: 3px;
    width: 100%;
    background-color: var(--Dblack);
  }

  #holder li a:hover {
    color: var(--black);
  }

  #holder li a:hover::after {
    display: block;
    background-color: var(--black);
    transition: 0.7s;
  }

  /* #check-box:checked ~ #holder {
    background-color: var(--White);
    display: block;
    width: 50em;
  } */

  /* #check-box:checked ~ #holder > li > a {
    visibility: visible;
  } */
  /* ==== Navbar ends  ==== */

  /* ==== Home ==== */
  #home {
    min-height: 115vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(
        178.23deg,
        rgba(121, 252, 231, 0.7) 17.54%,
        rgba(255, 255, 255, 0) 98.5%
      ),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
  }

  .title {
    display: block;
    text-align: left;
    padding: 0.5%;
    font-size: 3.5em;
    font-family: "poppins";
    margin: 100px 0px 30px 150px;
    z-index: 9;
  }

  .pi-btn {
    margin-left: 155px;
  }

  .boat {
    height: 13rem;
    width: 13rem;
    position: absolute;
    z-index: 1;
    left: 25px;
    bottom: 270px;
    right: 50vw;
    margin-left: 15vw;
  }

  .sea {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 300px;
  }

  .sun {
    position: absolute;
    right: 20vh;
    left: 43vw;
    bottom: 90px;
    height: 35rem;
    width: 35rem;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      rgba(249, 164, 79, 0.42) 60.47%,
      rgba(205, 114, 21, 0) 118.59%
    );
    opacity: 0.6;
  }

  .land {
    position: absolute;
    right: 0;
    bottom: 300px;
    height: 250px;
    width: 600px;
  }

  /* ==== Service ==== */

  .service-row {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    height: 45vh;
    margin-top: 3rem;
  }

  .pic-side {
    text-align: center;
  }

  .service-heading {
    margin-bottom: 1.5rem;
    text-align: left !important;
    font-family: "Nunito";
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  .service-img {
    display: block;
    margin: auto;
    margin-top: 10%;
    width: 300px;
    height: 300px;
  }

  .info-side {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2%;
    font-family: "Roboto";
    margin-bottom: 1rem;
  }
  .btn-holder {
    padding-top: 2rem;
    text-align: left;
  }

  /*Testimonials*/

  #testimonials {
    min-height: 60vh;
  }
  .testimonial-row {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .testimonial-pic {
    min-height: 200px;
    text-align: center;
    background-color: var(--white);
    border-radius: 25px 0px 0px 25px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }
  .person-bg {
    display: inline-block;
    text-align: center;
    height: 280px;
    width: 280px;
    background-image: url(../img/person-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .person {
    position: relative;
    top: 50px;
    height: 150px !important;
    width: 150px !important;
    border-radius: 50%;
    display: initial !important;
  }
  .testimonial-review {
    min-height: 200px;
    padding: 1.5rem;
    background-color: var(--white);
    border-radius: 0px 25px 25px 0px;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.1);
  }

  .review {
    margin-top: 3rem;
    font-family: "Roboto";
    text-align: center;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .client-details {
    margin-top: 3rem;
    height: 50px;
    text-align: right;
  }

  .client-name {
    margin-top: 2rem;
    color: var(--black);
    text-transform: capitalize;
    font-weight: 900;
    font-size: 1.2rem;
    font-family: "Nunito";
    color: var(--Dblack);
    margin-bottom: 0px;
  }
  .website {
    color: var(--black);
    text-transform: lowercase;
    font-style: italic;
    font-family: "Roboto";

    color: var(--black);
    font-size: 1rem;
  }

  /*About*/

  #about {
    margin-top: 10vh;
    min-height: 70vh;
  }

  .about-row {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 2rem;
  }

  .about-heading {
    margin-top: 3rem;
    font-family: "poppins";
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 3rem;
  }

  .about-box {
    height: 100%;
    background-color: var(--white);
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
  }

  .logo-box {
    width: 250px;
    height: 250px;
    margin: auto auto;
  }

  .Social-media-holder {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 3rem;
    padding: 0px 60px;
    height: 100px;
  }

  .soc-round {
    margin: auto auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--bay);
    text-align: center;
    color: var(--Dblack);
    transition: ease-in 300ms;
  }

  .soc-round > i {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .soc-round:hover {
    margin: auto auto;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--Dblack);
    color: var(--white);
    text-align: center;
  }
  .soc-round > i:hover {
    font-size: 1.5rem;
    line-height: 60px;
  }

  .about-info {
    padding: 0px 15px;
  }

  #about-text {
    line-height: 2rem;
    font-size: 1.2rem;
    font-family: "Roboto";
  }

  /*contact*/

  #contact {
    min-height: 90vh;
  }

  .con-bg {
    background-image: url(../img/contact_bg.png);
    width: 100%;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  .contact-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .con-left {
    padding: 0px 60px;
  }
  .contact-item {
    min-height: 90px;
    width: 100%;
    margin-top: 1rem;
  }

  #contact-icon {
    font-size: 2rem;
    color: var(--Dblack);
  }

  #info-head {
    font-size: 1.5rem;
    font-family: "Nunito";
    display: inline-block;
    padding-left: 1rem;
    margin-bottom: 0.8rem;
  }

  #contact-info {
    display: block;
    font-family: "Roboto";
    margin-top: 0px;
    font-size: 1rem;
    margin-left: 3rem;
    text-decoration: none;
    color: var(--black);
  }

  .con-right {
    padding: 0px 0px;
  }

  .contact-box {
    padding: 8%;
    text-align: left;
    border-radius: 25px;
    background-color: var(--white);
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
  }
  .box-heading {
    font-size: 2.5rem;
    margin-top: 0px;
    font-family: "Nunito";
    color: var(--Dblack);
    font-weight: 800;
  }

  .text-box {
    padding: 3%;
    width: 90%;
    margin-top: 30px;
    border-radius: 20px;
    border: none;
    font-size: 1.2rem;
    box-shadow: -4px -2px 8px rgba(0, 0, 0, 0.161);
  }

  .con-btn {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.161);
    margin-top: 30px;
    background-color: var(--white);
    border-radius: 25px;
    padding: 1rem 1.2rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .con-btn:hover {
    box-shadow: 5px 3px 20px rgba(0, 0, 0, 0.2);
  }

  .con-btn > i {
    color: var(--Dblack);
    padding-right: 15px;
    font-size: 1em;
    cursor: pointer;
  }

  /*==footer==*/

  #footer {
    max-height: 30vh;
  }

  .footer-bg {
    background-color: var(--black);
  }

  .footer-row {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .footer-logo img {
    width: 100px;
    height: 100px;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
  }

  .footer-heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: "Nunito";
    margin-bottom: 0.5rem;
    color: var(--white);
  }

  .logo-text {
    font-size: 1rem;
    color: var(--white);
    font-family: "Roboto";
    margin-left: 2.7rem;
  }

  .footer-list {
    list-style: none;
    padding-left: 0rem;
  }
  .footer-list > li {
    margin-bottom: 1rem;
  }
  .footer-list > li > a {
    font-size: 1rem;
    color: var(--bay);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a:hover {
    font-size: 1rem;
    color: var(--white);
    text-transform: capitalize;
    font-family: "Roboto";
  }

  .footer-list > li > a > i {
    margin-right: 1rem;
  }
  .copyrigths {
    background-color: var(--Dblack);
    text-align: center;
  }
  #copyrigths-text {
    font-size: 1rem;
    margin: 0px;
    line-height: 2.5rem;
    height: 2.5rem;
    text-transform: capitalize;
    font-family: "Roboto";
    color: var(--white);
  }
}
